import * as React from 'react';
import {Container} from 'react-bootstrap';

import {Highlight} from './Highlight';
import {Tutorial} from './Tutorial';

const containerStyles: React.CSSProperties = {
  position: 'relative',
  paddingTop: '3rem',
  fontFamily: 'Courier',
  textAlign: 'center',
  width: '60%',
};

export const Home = () => {
  console.log(React);
  return (
    <Container style={containerStyles}>
      <Highlight />
      <Tutorial />
    </Container>
  );
};
