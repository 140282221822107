import firebase from 'firebase/app';
import * as React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import {authUIConfig} from '../firebase/FirebaseUI';

export const Login: React.FC = () => {
  return (
    <>
      <h1 style={{textAlign: 'center'}}> Sign-In </h1>
      <StyledFirebaseAuth
        uiConfig={authUIConfig}
        firebaseAuth={firebase.auth()}
      />
    </>
  );
};
