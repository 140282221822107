import * as React from 'react';
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap';

import {LinkCard} from './LinkCard';
import {SelectContainer, Tickmark, TrashIcon} from './LinkCardSelect.styles';
import {DeleteLinkContext} from './MyData';

import {LinkPreviewType} from '../helpers/LinkPreviewType';

type Props = {
  linkPreviewData: LinkPreviewType;
  selected: boolean;
  onClick: (url: string) => void;
};

export const LinkCardSelect: React.FC<Props> = ({
  linkPreviewData,
  selected,
  onClick,
}) => {
  const deleteLink = React.useContext(DeleteLinkContext);

  const handleDeleteClick = (event: React.MouseEvent) => {
    // stop click event to bubble to parent element (card in this case)
    event.stopPropagation();
    deleteLink(linkPreviewData.url);
  };

  return (
    <SelectContainer
      selected={selected}
      onClick={() => onClick(linkPreviewData.url)}
    >
      <Tickmark />
      <LinkCard data={linkPreviewData} />
      <TrashIcon>
        <OverlayTrigger
          key='top'
          placement='top'
          overlay={<Tooltip id='tooltip'>Delete Data</Tooltip>}
        >
          <Button variant='danger' size='sm' onClick={handleDeleteClick}>
            <i className='fa fa-trash' />
          </Button>
        </OverlayTrigger>
      </TrashIcon>
    </SelectContainer>
  );
};
