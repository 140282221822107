import * as React from 'react';
import {Navbar, Container, Nav} from 'react-bootstrap';
import {Link} from 'react-router-dom';

export const NavBar: React.FC = () => {
  const links = (
    <Nav className='me-auto' variant='pills' defaultActiveKey='home'>
      <Nav.Link as={Link} to='/home' eventKey='home'>
        Home
      </Nav.Link>
      <Nav.Link as={Link} to='/data' eventKey='data'>
        My Data
      </Nav.Link>
      <Nav.Link as={Link} to='/profile' eventKey='profile'>
        My Account
      </Nav.Link>
    </Nav>
  );

  return (
    <Navbar bg='dark' variant='dark' sticky='top'>
      <Container>
        <Navbar.Brand href='#data'>
          <img
            src='/coinColor128.png'
            width='30'
            height='30'
            className='d-inline-block align-top'
          />
        </Navbar.Brand>
        {links}
      </Container>
    </Navbar>
  );
};
