import * as React from 'react';
import {Image, Row, Col, Card} from 'react-bootstrap';

import {LinkPreviewType} from '../helpers/LinkPreviewType';

type Props = {
  data: LinkPreviewType;
};

const overflowStyle: React.CSSProperties = {
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  overflow: 'hidden',
  // whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};

export const LinkCard = ({data: linkPreview}: Props) => {
  const onUrlClicked = (event: React.MouseEvent) => {
    // stop click event to bubble to parent element (card in this case)
    event.stopPropagation();
    window.open(linkPreview.url);
  };

  const imageUrl =
    linkPreview.images && (linkPreview.images[0] ?? '') !== ''
      ? linkPreview.images[0]
      : './image.svg';

  return (
    <Card bg='light'>
      <Card.Body>
        <Row>
          <Col xs={4}>
            <Image
              src={imageUrl}
              style={{objectFit: 'contain', cursor: 'pointer', padding: '5px'}}
              width='100%'
              height='100%'
              onClick={onUrlClicked}
            />
          </Col>
          <Col xs={8}>
            <Row>
              <Card.Link
                style={{
                  ...overflowStyle,
                  cursor: 'pointer',
                }}
                onClick={onUrlClicked}
              >
                {linkPreview.title}
              </Card.Link>
              <Card.Text style={overflowStyle}>
                {new Date(linkPreview.lastVisitTime).toDateString()}
              </Card.Text>
              <Card.Text style={overflowStyle}>
                {linkPreview.description}
              </Card.Text>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
