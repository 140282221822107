import {LinkPreviewType} from './LinkPreviewType';

export const emptyLinkPreview: LinkPreviewType = {
  url: '',
  title: 'no title',
  siteName: '',
  description: 'no description',
  mediaType: '',
  contentType: '',
  images: [],
  videos: [],
  requestedUrl: '',
  user: '',
  fetchTime: -1,
  lastVisitTime: -1,
};

export function IsDataActivityMatch(
  linkPreview: LinkPreviewType,
  activity: string | undefined,
) {
  // Specific match
  let isMatch = false;
  switch (activity) {
    case 'watch':
      isMatch =
        linkPreview.mediaType.includes('video') ||
        linkPreview.url.includes('/watch/') ||
        (linkPreview.videos ? linkPreview.videos.length > 0 : false);
      break;
    case 'read':
      isMatch = linkPreview.mediaType.includes('article');
      break;
    case 'listen':
      isMatch =
        linkPreview.mediaType.includes('audio') ||
        linkPreview.mediaType.includes('music');
      break;
    case 'all':
    default:
      isMatch = true;
  }
  return isMatch;
}

export const sortUrlsByVisitCount = <T extends {visitCount: number}>(
  items: T[],
): T[] => {
  return items.sort((a, b) => b.visitCount - a.visitCount);
};

export const sortUrlsByLastVisitTime = <T extends {lastVisitTime: number}>(
  items: T[],
): T[] => {
  return items.sort((a, b) => b.lastVisitTime - a.lastVisitTime);
};

export const dedupUrlsByTitle = <T extends {title: string}>(urls: T[]): T[] => {
  const uniqueUrls: T[] = [];
  const uniqueTitles: string[] = [];
  urls.forEach((url) => {
    if (!url.title) {
      // keep as it is those dont have a clue to dedup
      uniqueUrls.push(url);
    } else {
      // remove those of duplicated title
      if (!uniqueTitles.includes(url.title)) {
        uniqueTitles.push(url.title);
        uniqueUrls.push(url);
      }
    }
  });
  return uniqueUrls;
};
