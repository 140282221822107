import firebase from 'firebase/app';

import {db} from './Firestore';
import {webBrowsingsRef} from './Queries';

import {emptyLinkPreview} from '../helpers/linkPreviewHelpers';
import {LinkPreviewType} from '../helpers/LinkPreviewType';
import '../helpers/String.extensions';

export async function getUrls(user: string): Promise<LinkPreviewType[]> {
  /**
   * TODO: https://github.com/meetai-org/datamarket/issues/54
   * Cost & perf issue when large size, load url in batch on needs.
   */
  const snapshot = await webBrowsingsRef(user).get();
  return snapshot.docs.map((doc) => ({...emptyLinkPreview, ...doc.data()}));
}

export async function getUrlCount(user: string): Promise<number> {
  /**
   * TODO: https://github.com/meetai-org/datamarket/issues/55
   * Cost & perf issue on large size collection.
   */
  const snapshot = await webBrowsingsRef(user).get();
  return snapshot.size;
}

export async function deleteAllUrls(user: string) {
  const batch = firebase.firestore().batch();
  const snapshot = await webBrowsingsRef(user).get();
  snapshot.docs.map((doc) => batch.delete(doc.ref));
  batch.commit().then(() => {
    alert('Your data has been delete succefully');
  });
  return;
}

export async function deleteUrl(user: string, url: string) {
  const doc = webBrowsingsRef(user).doc(url.hashCode().toString(16));

  try {
    await doc.delete();
  } catch (error) {
    console.error('doc deleted failed: ', error);
  }
}

export async function queryUrls(
  user: firebase.User,
  queryTerm: string,
): Promise<LinkPreviewType[]> {
  if (!user.email) {
    return [];
  }

  const regexp = /(\w|\s)*\w(?=")|\w+/g;
  const keyWordsArr = queryTerm.match(regexp);
  if (!keyWordsArr) {
    return [];
  }

  const result: LinkPreviewType[] = [];
  const getQueriedData = keyWordsArr.map(async (keyWord) => {
    const linkInfoDocs = db
      .collectionGroup('webBrowsings')
      .where('keyWords', 'array-contains', keyWord);
    const linkInfoSnapShots = await linkInfoDocs.get();
    linkInfoSnapShots.forEach((doc) => {
      result.push(doc.data() as LinkPreviewType);
    });
  });
  //TODO: use allSeattled
  await Promise.all(getQueriedData);
  return result;
}
