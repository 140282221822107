import * as React from 'react';
import {
  Button,
  Col,
  Container,
  Form,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';

import {LinkCardSelect} from './LinkCardSelect';
import {DeleteLinkContext} from './MyData';

import {IsDataActivityMatch} from '../helpers/linkPreviewHelpers';
import {LinkPreviewType} from '../helpers/LinkPreviewType';
import {useInfiniteScroll} from '../hooks/useInfiniteScroll';
import {useSelectAll} from '../hooks/useSelectAll';

export type Props = {
  linkPreviews: LinkPreviewType[];
  activity: string;
  selectedUrls: Set<string>;
  onSelect: React.Dispatch<React.SetStateAction<Set<string>>>;
};

export const MyDataActivityTab: React.FC<Props> = ({
  linkPreviews,
  activity,
  selectedUrls,
  onSelect,
}) => {
  const deleteLink = React.useContext(DeleteLinkContext);

  // useInfiniteScroll to generate data to render
  const predicate = React.useCallback(
    (linkPreview: LinkPreviewType) =>
      IsDataActivityMatch(linkPreview, activity),
    [activity],
  );
  const noTransform = React.useCallback(
    async (items: LinkPreviewType[]): Promise<LinkPreviewType[]> =>
      new Promise((r) => r(items)),
    [],
  );
  const {
    data: current,
    index,
    hasMore,
    getMoreData,
  } = useInfiniteScroll(linkPreviews, noTransform, predicate);

  const loadedPercentage = React.useMemo(
    () => (100 * index) / linkPreviews.length,
    [index, linkPreviews.length],
  );

  // select hooks
  const [isAllSelected, selectAll, unselectAll] = useSelectAll(
    current.map((info) => info.url),
    selectedUrls,
    onSelect,
  );

  // event listeners
  const handleSelected = React.useCallback(
    (url: string) => {
      onSelect((prev) => {
        const next = new Set(prev);
        if (prev.has(url)) {
          next.delete(url);
        } else {
          next.add(url);
        }
        return next;
      });
    },
    [onSelect],
  );

  const handleDeleteSelectedClicked = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();

      selectedUrls.forEach((url) => deleteLink(url));
    },
    [deleteLink, selectedUrls],
  );

  const selectAllBar = React.useMemo(
    () => (
      <Container className='mb-2'>
        <Row style={{height: '31px'}}>
          <Col xs='auto'>
            <Form.Check
              type='checkbox'
              id='selectAll'
              label={`Selected (${selectedUrls.size}/${current.length})`}
              checked={isAllSelected}
              onChange={(event) =>
                event.target.checked ? selectAll() : unselectAll()
              }
            />
          </Col>
          <Col xs={1}>
            {selectedUrls.size != 0 && (
              <OverlayTrigger
                key='top'
                placement='top'
                overlay={
                  <Tooltip id='tooltip'>Delete All Selected Data</Tooltip>
                }
              >
                <Button
                  variant='danger'
                  size='sm'
                  onClick={handleDeleteSelectedClicked}
                >
                  <i className='fa fa-trash' />
                </Button>
              </OverlayTrigger>
            )}
          </Col>
        </Row>
      </Container>
    ),
    [
      isAllSelected,
      selectedUrls.size,
      current.length,
      handleDeleteSelectedClicked,
      selectAll,
      unselectAll,
    ],
  );

  const loadedLinkPreviewItems = React.useMemo(
    () =>
      current.map((data, index) => (
        <LinkCardSelect
          key={index}
          linkPreviewData={data}
          selected={selectedUrls.has(data.url)}
          onClick={handleSelected}
        />
      )),
    [current, handleSelected, selectedUrls],
  );

  return (
    <>
      {selectAllBar}
      <InfiniteScroll
        dataLength={current.length}
        next={getMoreData}
        hasMore={hasMore}
        loader={
          <Container className='d-flex justify-content-center'>
            <Spinner animation='border' />
            <span>{loadedPercentage.toFixed(0) + '%'}</span>
          </Container>
        }
      >
        {loadedLinkPreviewItems}
      </InfiniteScroll>
    </>
  );
};
