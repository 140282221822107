import * as React from 'react';
import {
  Container,
  Dropdown,
  DropdownButton,
  FormControl,
  InputGroup,
} from 'react-bootstrap';

import {CurrentUserContext} from './App';
import {Container as MyContainer} from './MyData.styles';
import {MyDataActivityTab} from './MyDataActivityTab';

import {deleteUrl, getUrls} from '../firebase/WebBrowsings';
import {dataTypeSelection, defaultDataType} from '../helpers/DataSelection';
import {LinkPreviewType} from '../helpers/LinkPreviewType';

export const DeleteLinkContext = React.createContext<(url: string) => void>(
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
);

export const MyData: React.FC = () => {
  const [feedLinksInfo, setFeedLinksInfo] = React.useState<
    Map<string, LinkPreviewType>
  >(new Map());
  const [searchTerm, setSearchTerm] = React.useState('');
  const [selectedUrls, setSelectedUrls] = React.useState<Set<string>>(
    new Set(),
  );

  const [dataType, setDataType] = React.useState(
    dataTypeSelection.get(defaultDataType)!,
  );

  const currentUserId = React.useContext(CurrentUserContext).email!;

  const deleteLinkItem = async (url: string) => {
    // delete link in DB
    await deleteUrl(currentUserId, url);

    // remove link item from local list as well
    setFeedLinksInfo((prevState) => {
      prevState.delete(url);
      return new Map(prevState);
    });

    // Update selectedUrls
    if (selectedUrls.has(url)) {
      setSelectedUrls((prev) => {
        prev.delete(url);
        return new Set(prev);
      });
    }
  };

  // Fetch my data from DB.
  React.useEffect(() => {
    (async function () {
      // update feed data when auth state updated
      const links: LinkPreviewType[] = (await getUrls(
        currentUserId,
      )) as LinkPreviewType[];

      setFeedLinksInfo(new Map(links.map((link) => [link.url, link])));
    })();
  }, [currentUserId]);

  const filteredLinks = React.useMemo(
    () =>
      [...feedLinksInfo.values()].filter((urlInfo) => {
        return (
          searchTerm === '' ||
          urlInfo.url.toLowerCase().includes(searchTerm) ||
          urlInfo.title.toLowerCase().includes(searchTerm)
        );
      }),
    [searchTerm, feedLinksInfo],
  );

  const handleDataTypeChange = (dataType: string) => {
    setDataType((prev) => dataTypeSelection.get(dataType) ?? prev);
  };

  const searchBar = (
    <Container>
      <InputGroup className='mb-3'>
        <FormControl
          placeholder='Search...'
          onChange={(e) => {
            setSearchTerm(e.target.value.toLowerCase());
          }}
        />
        <DropdownButton
          variant='secondary'
          title='Filter'
          onSelect={(eventKey) => handleDataTypeChange(eventKey!)}
        >
          {[...dataTypeSelection.keys()].map((value) => (
            <Dropdown.Item eventKey={value} key={value}>
              {value}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </InputGroup>
    </Container>
  );

  if (feedLinksInfo.size == 0) {
    return null;
  }

  return (
    <DeleteLinkContext.Provider value={deleteLinkItem}>
      <MyContainer>
        {searchBar}
        <MyDataActivityTab
          activity={dataType}
          linkPreviews={filteredLinks}
          selectedUrls={selectedUrls}
          onSelect={setSelectedUrls}
        />
      </MyContainer>
    </DeleteLinkContext.Provider>
  );
};
