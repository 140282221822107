import * as React from 'react';
import {Fade} from 'react-awesome-reveal';
import {Button, Container} from 'react-bootstrap';

const containerStyles: React.CSSProperties = {
  position: 'relative',
  paddingTop: '3rem',
  fontFamily: 'Courier',
  textAlign: 'center',
};

export const Highlight = () => {
  return (
    <Container style={containerStyles}>
      <Fade direction='down' delay={200} triggerOnce>
        <h1>Collect And Discover During Browsing.</h1>
      </Fade>
      <Fade delay={400} triggerOnce>
        <p>
          2Cent provides an innovative way to discover new world given browsing
          history.
        </p>
      </Fade>
      <Fade direction='up' delay={600} triggerOnce>
        <Button
          color='primary'
          href='https://chrome.google.com/webstore/detail/2cent/iagebcdbadgiiodpheplbocgkeglkglm?hl=en&authuser=0'
        >
          Download
        </Button>
      </Fade>
    </Container>
  );
};
