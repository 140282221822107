import firebase from 'firebase/app';
import * as React from 'react';
import {HashRouter as Router, Redirect, Route, Switch} from 'react-router-dom';

import {Home} from './Home';
import {Login} from './Login';
import {MyData} from './MyData';
import {NavBar} from './NavBar';
import {UserProfile} from './UserProfile';

import {useCurrentUser} from '../hooks/useCurrentUser';

// import {ScrollReveal} from '../utils/ScrollReveal';

export const CurrentUserContext = React.createContext<firebase.User>(
  {} as firebase.User,
);

export const App: React.FC = () => {
  const [isAuthoring, currentUser] = useCurrentUser();

  // Auth is still ongoing
  if (isAuthoring) {
    return null;
  }

  // Do login again since no authenticated user.
  if (!currentUser) {
    return <Login />;
  }

  // const childRef = React.useRef();

  return (
    <Router>
      <CurrentUserContext.Provider value={currentUser}>
        <NavBar />
        <Switch>
          <Route path='/home' render={() => <Home />} />
          <Route path='/data' render={() => <MyData />} />
          <Route path='/profile' render={() => <UserProfile />} />
          <Route exact path='/' render={() => <Redirect to='/home' />} />
        </Switch>
      </CurrentUserContext.Provider>
    </Router>
  );
};
