// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

import 'firebase/analytics';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// prod env config
const firebaseConfig = {
  apiKey: 'AIzaSyCJAmaff-XGwuEmux6rwgNaVu2yzUtERXs',
  authDomain: 'datamarket-8f280.firebaseapp.com',
  projectId: 'datamarket-8f280',
  storageBucket: 'datamarket-8f280.appspot.com',
  messagingSenderId: '697343654701',
  appId: '1:697343654701:web:3003359d91ac593a4671a4',
  measurementId: 'G-6KHMMR71VS',
};

// dev env config
/*const firebaseConfig = {
  apiKey: 'AIzaSyBA15WqouLJBiRe9cNHOnlaoY566eBeNL8',
  authDomain: 'datamarket-dev-9c48d.firebaseapp.com',
  projectId: 'datamarket-dev-9c48d',
  storageBucket: 'datamarket-dev-9c48d.appspot.com',
  messagingSenderId: '357880764696',
  appId: '1:357880764696:web:34bd9954e299b7b33da66d',
  measurementId: 'G-7HMN3HC5KE'
};*/

firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
