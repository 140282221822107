export const timePeriodSelection = new Map([
  ['Last hour', 1000 * 60 * 60],
  ['Today', 1000 * 60 * 60 * 24],
  ['This week', 1000 * 60 * 60 * 24 * 7],
  ['This month', 1000 * 60 * 60 * 24 * 30],
]);

export const defaultTimePeriod = 'Today';

export const dataTypeSelection = new Map<string, string>([
  ['all', 'all'],
  ['watch', 'watch'],
  ['read', 'read'],
  ['listen', 'listen'],
]);

export const defaultDataType = 'all';
