import * as React from 'react';

const ITEMS_PER_BATCH = 10;

type Output<T> = {
  data: T[];
  index: number;
  hasMore: boolean;
  getMoreData: () => void;
};

export const useInfiniteScroll = <T_IN, T_OUT>(
  data: T_IN[],
  transform: (input: T_IN[]) => Promise<T_OUT[]>,
  predicate?: (item: T_OUT) => boolean,
): Output<T_OUT> => {
  const [index, setIndex] = React.useState(0);
  const [hasMore, setHasMore] = React.useState(true);
  const [current, setCurrent] = React.useState<T_OUT[]>([]);

  const fetchNextBatch = React.useCallback(
    async (startAt: number, batchSize: number): Promise<T_OUT[]> => {
      let res: T_OUT[] = [];

      // console.log(`fetchNextBatch at ${startAt}, total =${data.length}`);

      while (res.length < batchSize && startAt < data.length) {
        // fetch the next batch and apply transform
        const input = data.slice(startAt, startAt + batchSize);

        const result = await transform(input);
        // console.log(`${startAt} - ${startAt + batchSize}: `, result);

        startAt += batchSize;

        // filter batch if predicate is available
        const filteredBatch = result.filter(
          (item) => predicate && predicate(item),
        );

        res = res.concat(filteredBatch);
        // console.log('accumulated: ', res);
        setIndex(startAt);
      }

      // console.log(`set startAt: ${startAt}, total =${data.length}`);

      if (startAt >= data.length) {
        setHasMore(false);
      }

      return res;
    },
    [data, predicate, transform],
  );

  const getMoreData = React.useCallback(() => {
    if (index >= data.length) {
      setHasMore(false);
      return;
    }

    fetchNextBatch(index, ITEMS_PER_BATCH).then((batch) => {
      setCurrent((prevState) => {
        const next = prevState.concat(batch);
        return next;
      });
    });
  }, [data.length, fetchNextBatch, index]);

  React.useEffect(() => {
    setHasMore(true);
    setIndex(0);
    fetchNextBatch(0, ITEMS_PER_BATCH).then((batch) => {
      setCurrent(batch);
    });
  }, [data.length, fetchNextBatch]);

  // console.log(`hasMore = ${hasMore}`, current);
  return {data: current, index, hasMore, getMoreData};
};
