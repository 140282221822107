import firebase from 'firebase/app';

import {userProfileDocRef} from './Queries';

import {UserProfileType} from '../components/UserProfile';

export async function getUserProfile(user: string): Promise<UserProfileType> {
  const snapshot = await userProfileDocRef(user).get();
  return snapshot.get('userProfile') as UserProfileType;
}

export async function updateUserProfile(
  user: string,
  userProfile: UserProfileType,
) {
  await userProfileDocRef(user).set(userProfile, {merge: true});
  alert('user profile updated');
}

export async function initUserProfile(user: firebase.User) {
  if (!user.email) {
    return;
  }

  const snapshot = await userProfileDocRef(user.email).get();
  // initialize the user profile if does not exist
  if (!snapshot.get('userProfile')) {
    const emptyUserProfile: UserProfileType = {
      displayedName: user.displayName || '',
      gender: '',
      location: '',
      position: '',
      company: '',
      expertise: '',
      school: '',
      degree: '',
      concentration: '',
    };
    userProfileDocRef(user.email)
      .set(emptyUserProfile, {merge: true})
      .then(() => alert('user profile updated'));
  }
}
