import * as React from 'react';

export const useSelectAll = <T>(
  items: T[],
  selectedItems: Set<T>,
  setSelectedItems: React.Dispatch<React.SetStateAction<Set<T>>>,
): [boolean, () => void, () => void] => {
  const isAllSelected = React.useMemo(
    () => items.every((item) => selectedItems.has(item)),
    [items, selectedItems],
  );

  const selectAll = React.useCallback(() => {
    setSelectedItems((prev) => new Set([...prev, ...items]));
  }, [setSelectedItems, items]);

  const unselectAll = () => {
    setSelectedItems((prev) => {
      const next = new Set(prev);
      items.forEach((item) => next.delete(item));
      return next;
    });
  };

  return [isAllSelected, selectAll, unselectAll];
};
