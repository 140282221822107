import firebase from 'firebase/app';
import * as React from 'react';

import {initUserProfile} from '../firebase/Profile';

export const useCurrentUser = (): [boolean, firebase.User | null] => {
  const [isAuthoring, setIsAuthoring] = React.useState(true);
  const [currentUser, setCurrentUser] = React.useState<firebase.User | null>(
    firebase.auth().currentUser,
  );

  React.useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        setIsAuthoring(false);
        setCurrentUser(user);
        if (user) {
          initUserProfile(user);
        }
      });
    return () => unregisterAuthObserver();
  }, []);

  return [isAuthoring, currentUser];
};
