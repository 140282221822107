import firebase from 'firebase/app';
import * as React from 'react';
import {
  Image,
  OverlayTrigger,
  Tooltip,
  Container,
  Row,
  Col,
  Badge,
  Card,
  Button,
  Form,
  FloatingLabel,
} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';

import {CurrentUserContext} from './App';

import {updateUserProfile} from '../firebase/Profile';
import {deleteAllUrls, getUrlCount} from '../firebase/WebBrowsings';
import {useUserProfile} from '../hooks/useUserProfile';

export type UserProfileType = {
  //self
  displayedName: string;
  gender: string;
  location: string;
  //work
  company: string;
  expertise: string;
  position: string;
  //edu
  school: string;
  degree: string;
  concentration: string;
};

export const UserProfile: React.FC = () => {
  const currentUser = React.useContext(CurrentUserContext);
  const currentUserId = currentUser.email!;
  const userProfileInfo: UserProfileType = useUserProfile(currentUserId);

  const [submittedCount, setSubmittedCount] = React.useState<number>(0);

  React.useEffect(() => {
    getUrlCount(currentUserId).then((count) => {
      setSubmittedCount(count);
    });
  }, [currentUserId]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const userProfileUpdate: UserProfileType = {
      //self
      displayedName: event.currentTarget.displayedName.value,
      gender: event.currentTarget.gender.value,
      location: event.currentTarget.location.value,
      //work
      position: event.currentTarget.position.value,
      company: event.currentTarget.company.value,
      expertise: event.currentTarget.expertise.value,
      //edu
      school: event.currentTarget.school.value,
      degree: event.currentTarget.degree.value,
      concentration: event.currentTarget.concentration.value,
    };

    updateUserProfile(currentUserId, userProfileUpdate);
  };

  const handleDeleteAll = () => {
    deleteAllUrls(currentUserId);
  };

  const routeHistory = useHistory();
  const handleUploadIntent = () => {
    routeHistory.push('/home');
  };

  const handleUpgradeIntent = () => {
    alert('No IMPL');
  };

  const authInfo = (
    <Col>
      <Card bg='light '>
        <Card.Body>
          <Card.Title>Signed In </Card.Title>
          <Card.Text>as {currentUser.displayName}</Card.Text>
          <OverlayTrigger
            placement='top'
            overlay={<Tooltip id='tooltip'>Sign out</Tooltip>}
          >
            <Button variant='secondary'>
              <Image
                src='./logout.svg'
                onClick={() => {
                  routeHistory.push('/discover');
                  firebase.auth().signOut();
                }}
                width='20px'
              />
            </Button>
          </OverlayTrigger>
        </Card.Body>
      </Card>
    </Col>
  );

  const dataInfo = (
    <Col>
      <Card bg='light'>
        <Card.Body>
          <Card.Title>Data</Card.Title>
          <Card.Text>
            Submited <Badge bg='success'>{submittedCount.toString()}</Badge>{' '}
          </Card.Text>
          <OverlayTrigger
            placement='top'
            overlay={<Tooltip id='tooltip'>Delete the uploaded data</Tooltip>}
          >
            <Button variant='danger'>
              <Image src='./trash.svg' onClick={handleDeleteAll} width='20px' />
            </Button>
          </OverlayTrigger>
        </Card.Body>
      </Card>
    </Col>
  );

  const usageInfo = (
    <Col>
      <Card bg='light'>
        <Card.Body>
          <Card.Title>Usage</Card.Title>
          <Card.Text>
            Used <Badge bg='warning'>50/100</Badge>
          </Card.Text>

          <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip id='tooltip'>
                Get more access by uploading your data
              </Tooltip>
            }
          >
            <Button variant='secondary' className='me-3'>
              <Image
                src='./upload.svg'
                onClick={handleUploadIntent}
                width='20px'
              />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement='top'
            overlay={<Tooltip id='tooltip'>Apply for unlimited access</Tooltip>}
          >
            <Button variant='secondary'>
              <Image
                src='./forever.svg'
                onClick={handleUpgradeIntent}
                width='20px'
              />
            </Button>
          </OverlayTrigger>
        </Card.Body>
      </Card>
    </Col>
  );

  const profileInfo = (
    <Col>
      <Card bg='light'>
        <Card.Body>
          <Card.Title>Profile</Card.Title>
          <Form onSubmit={handleSubmit}>
            <Row className='mb-3'>
              <Col>
                <FloatingLabel label='Name'>
                  <Form.Control
                    type='text'
                    name='displayedName'
                    defaultValue={userProfileInfo?.displayedName}
                  />
                </FloatingLabel>
              </Col>

              <Col>
                <FloatingLabel label='Gender'>
                  <Form.Control
                    type='text'
                    name='gender'
                    defaultValue={userProfileInfo?.gender}
                  />
                </FloatingLabel>
              </Col>

              <Col>
                <FloatingLabel label='Location'>
                  <Form.Control
                    type='text'
                    name='location'
                    defaultValue={userProfileInfo?.location}
                  />
                </FloatingLabel>
              </Col>
            </Row>

            <Row className='mb-3'>
              <Col>
                <FloatingLabel label='Company'>
                  <Form.Control
                    type='text'
                    name='company'
                    placeholder='company'
                    defaultValue={userProfileInfo?.company}
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel label='Position'>
                  <Form.Control
                    type='text'
                    name='position'
                    placeholder='position'
                    defaultValue={userProfileInfo?.position}
                  />
                </FloatingLabel>
              </Col>

              <Col>
                <FloatingLabel label='Expertise'>
                  <Form.Control
                    type='text'
                    name='expertise'
                    defaultValue={userProfileInfo?.expertise}
                  />
                </FloatingLabel>
              </Col>
            </Row>

            <Row className='mb-3'>
              <Col>
                <FloatingLabel label='School'>
                  <Form.Control
                    type='text'
                    name='school'
                    defaultValue={userProfileInfo?.school}
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel label='Degree'>
                  <Form.Control
                    type='text'
                    name='degree'
                    defaultValue={userProfileInfo?.degree}
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel label='Concentration'>
                  <Form.Control
                    type='text'
                    name='concentration'
                    defaultValue={userProfileInfo?.concentration}
                  />
                </FloatingLabel>
              </Col>
            </Row>

            <Button variant='primary' type='submit'>
              Update
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Col>
  );

  return (
    <Container>
      <Row xs={3} md={3} className='mb-3'>
        {authInfo}
        {dataInfo}
        {usageInfo}
      </Row>
      <Row xs={1} md={1} className='mb-3'>
        {profileInfo}
      </Row>
    </Container>
  );
};
