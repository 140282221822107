import {db} from './Firestore';

export const userProfileDocRef = (user: string) =>
  db.collection('users').doc(user);

export const webBrowsingsRef = (user: string) =>
  db.collection('users').doc(user).collection('webBrowsings');

export const friendsRef = (user: string) =>
  db.collection('users').doc(user).collection('friends');
