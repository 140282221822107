import * as React from 'react';
import {Fade} from 'react-awesome-reveal';
import {Container, Image, Row, Col} from 'react-bootstrap';

const containerStyles: React.CSSProperties = {
  position: 'relative',
  paddingTop: '3rem',
  textAlign: 'center',
  width: '100%',
};

const imageStyles: React.CSSProperties = {
  position: 'relative',
  paddingTop: '3rem',
  textAlign: 'center',
  width: '100%',
};

const InstallSection = () => {
  return (
    <Container style={containerStyles}>
      <Fade direction='up' delay={800} triggerOnce>
        <h2>Install and Pin 2Cent</h2>
      </Fade>
      <Row>
        <Col xs={6}>
          <Fade delay={1000} triggerOnce>
            <Image src={'./extension_unpin.png'} style={imageStyles} rounded />
          </Fade>
        </Col>
        <Col xs={6}>
          <Fade delay={1000} triggerOnce>
            <Image src={'./extension_pin.png'} style={imageStyles} rounded />
          </Fade>
        </Col>
      </Row>
    </Container>
  );
};

const DiscoverSection = () => {
  return (
    <Container style={containerStyles}>
      <Fade direction='up' delay={1200} triggerOnce>
        <h2>Discover Interestings</h2>
      </Fade>
      <Row>
        <Col xs={6}>
          <Fade delay={1400} triggerOnce>
            <Image
              src={'./discover_most_relevant.png'}
              style={imageStyles}
              rounded
            />
          </Fade>
        </Col>
        <Col xs={6}>
          <Fade delay={1400} triggerOnce>
            <Image
              src={'./discover_most_reviewed.png'}
              style={imageStyles}
              rounded
            />
          </Fade>
        </Col>
      </Row>
    </Container>
  );
};

const FavoriteSection = () => {
  return (
    <Container style={containerStyles}>
      <Fade direction='up' delay={1200} triggerOnce>
        <h2>Favorite If You Like</h2>
      </Fade>
      <Row>
        <Col xs={6}>
          <Fade delay={1600} triggerOnce>
            <Image src={'./favorite_story.png'} style={imageStyles} rounded />
          </Fade>
        </Col>
        <Col xs={6}>
          <Fade delay={1600} triggerOnce>
            <Image src={'./favorite_tab.png'} style={imageStyles} rounded />
          </Fade>
        </Col>
      </Row>
    </Container>
  );
};

const CreateSection = () => {
  return (
    <Container style={containerStyles}>
      <Fade direction='up' delay={1400} triggerOnce>
        <h2>Create Your Own Stories</h2>
      </Fade>
      <Row>
        <Col xs={6}>
          <Fade delay={1600} triggerOnce>
            <Image
              src={'./create_collection_start.png'}
              style={imageStyles}
              rounded
            />
          </Fade>
        </Col>
        <Col xs={6}>
          <Fade delay={1600} triggerOnce>
            <Image
              src={'./create_collection_edit_panel.png'}
              style={imageStyles}
              rounded
            />
          </Fade>
        </Col>
      </Row>
    </Container>
  );
};

const AddLinkSection = () => {
  return (
    <Container style={containerStyles}>
      <Fade direction='up' delay={1800} triggerOnce>
        <h2>Add Link To Your Stories</h2>
      </Fade>
      <Row>
        <Image
          src={'./create_collection_add_link.png'}
          style={imageStyles}
          rounded
        />
      </Row>
    </Container>
  );
};

export const Tutorial = () => {
  console.log(React);
  return (
    <Container>
      <InstallSection />
      <DiscoverSection />
      <FavoriteSection />
      <CreateSection />
      <AddLinkSection />
    </Container>
  );
};
