import * as React from 'react';

import {UserProfileType} from '../components/UserProfile';
import {getUserProfile} from '../firebase/Profile';

export const useUserProfile = (userId: string) => {
  const [userProfile, setUserProfile] = React.useState<UserProfileType>(
    {} as UserProfileType,
  );

  React.useEffect(() => {
    getUserProfile(userId).then((userProfile) => setUserProfile(userProfile));
  }, [userId]);

  return userProfile;
};
