import styled from 'styled-components';

export const TrashIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: none;
`;

export const Tickmark = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  border: 20px solid;
  border-color: #198754 transparent transparent #198754;
  display: none;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    top: -15px;
    left: -15px;
    width: 15px;
    height: 5px;
    border: 3px solid;
    border-color: transparent transparent #fff #fff;
    transform: rotate(-45deg);
  }
`;

export const SelectContainer = styled.div<{selected: boolean}>`
  position: relative;
  border: 2px solid transparent;
  border-color: ${(props) => (props.selected ? '#198754' : 'transparent')};
  border-radius: 5px;
  user-select: none;

  ${Tickmark} {
    border-color: ${(props) =>
      props.selected
        ? '#198754 transparent transparent #198754'
        : 'grey transparent transparent grey'};
    display: ${(props) => (props.selected ? 'block' : 'none')};
  }

  &:hover ${Tickmark} {
    display: block;
  }
  &:hover ${TrashIcon} {
    display: block;
  }
`;
